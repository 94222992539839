<template>
  <v-card class="elevation-0 rounded-lg" v-bind="$attrs">
    <v-row no-gutters>
      <v-col cols="3" class="px-1">
        <div class="text-center card-bg border-radius-8 py-2">
          <div class="info-field-number primary--text">
            {{ days | twoDigits }}
          </div>
          <div class="normal-card-text subtitle--text">Days</div>
        </div>
      </v-col>
      <v-col cols="3" class="px-1">
        <div class="text-center card-bg border-radius-8 py-2">
          <div class="info-field-number primary--text">
            {{ hours | twoDigits }}
          </div>
          <div class="normal-card-text subtitle--text">Hours</div>
        </div>
      </v-col>
      <v-col cols="3" class="px-1">
        <div class="text-center card-bg border-radius-8 py-2">
          <div class="info-field-number primary--text">
            {{ minutes | twoDigits }}
          </div>
          <div class="normal-card-text subtitle--text">Minutes</div>
        </div>
      </v-col>
      <v-col cols="3" class="px-1">
        <div class="text-center card-bg border-radius-8 py-2">
          <div class="info-field-number primary--text">
            {{ seconds | twoDigits }}
          </div>
          <div class="normal-card-text subtitle--text">Seconds</div>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<style scoped>
.number {
  font-style: normal;
  font-weight: bold;
  font-size: 36px !important;
  line-height: 32px !important;
  letter-spacing: -0.02em;
}
.text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px !important;
  line-height: 20px !important;
}
</style>

<script>
export default {
  props: {
    targetDate: String,
  },
  mounted() {
    this.countdownInterval = setInterval(() => {
      this.now = Math.trunc(new Date().getTime() / 1000);
    }, 1000);
  },
  destroyed() {
    clearInterval(this.countdownInterval);
  },
  data() {
    return {
      now: "",
      countdownInterval: null,
    };
  },
  computed: {
    dateInMilliseconds() {
      if (!this.targetDate) return 0;
      return Math.trunc(Date.parse(this.targetDate) / 1000);
    },
    seconds() {
      return (this.dateInMilliseconds - this.now) % 60;
    },
    minutes() {
      return Math.trunc((this.dateInMilliseconds - this.now) / 60) % 60;
    },
    hours() {
      return Math.trunc((this.dateInMilliseconds - this.now) / 60 / 60) % 24;
    },
    days() {
      if (!this.now) return 0;
      return Math.trunc((this.dateInMilliseconds - this.now) / 60 / 60 / 24);
    },
  },
};
</script>
